import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import FeatureIcon from '../components/FeatureIcon';
import Funfact from '../components/Funfact';
// import TeamMemberGrid from '../components/TeamMemberGrid';
// import TestimonialSlider from '../components/TestimonialSlider';
// import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ModalVideo from 'react-modal-video'
class About extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>About Us</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--120">
                {/*About section start*/}
                <div className="about-section section-space--inner--bottom--120">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-image-two">
                        <img src="assets/img/about/about-3.jpg" alt="" />
                            <span className="video-popup">
                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OrS-93U4AYQ' onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <i className="ion-ios-play-outline" />
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-content-two">
                        <h3>Welcome to Kingpin Construction LLC</h3>
                        <h1>20 Years of Experience in the Construction Industry</h1>
                        <h4>Our Vision! </h4>
                        <p>Our vision is to be the premier project management and construction firm, renowned for our innovative solutions, commitment to excellence, and unwavering dedication to client satisfaction. We strive to lead the industry by seamlessly integrating project management expertise with cutting-edge construction practices, delivering exceptional results that exceed expectations and inspire confidence. Our goal is to forge enduring partnerships, create sustainable value, and shape the future of construction through integrity, collaboration, and continuous improvement.
.</p>
                        <a href="services" className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Our Services</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Feature Icon */}
                <FeatureIcon background = "grey-bg"/>

                {/*About section start*/}
                <div className="about-section section-space--inner--120">
                <div className="container">
                    <div className="about-wrapper row">
                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                        <div className="about-image about-image-1">
                        <img src="assets/img/about/about-1.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                        <div className="about-image about-image-2">
                        <img src="assets/img/about/about-2.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                        <div className="about-content about-content-1">
                        <h2>BUILDING COMMUNITY EQUITY ONE PROJECT AT A TIME.</h2>
                        <p>We are concious of the social, economic and environmental benefits that our work provides for our neighbors and our partners – and we aim to deliver finished projects that will be a source of community pride for many generations to come.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-4">
                        <div className="about-content about-content-2">
                        <h2>NURTURING A JUST AND ETHICAL WORK ENVIRONMENT</h2>

<p>We recognizine the importance of investing in and cultivating a diverse workforce to broaden opportunities for everyone. By promoting diverse local economies, we provide a wide range of employment opportunities and tap potential new markets.</p>
                        <a href={`/contact-us`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Contact us</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Fun fact */}
                <Funfact/>
                
                {/* Team member */}
                {/* <TeamMemberGrid/> */}
                
                {/* Testimonial Slider */}
                {/* <TestimonialSlider/> */}
                
                {/* Brand logo */}
                {/* <BrandLogoSlider background = "" /> */}

                </div>


                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default About;