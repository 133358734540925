import React from 'react'
import { useParams } from 'react-router-dom';
import { contentData } from '../contentData';
import MobileMenu from '../components/MobileMenu';
import Footer from '../components/Footer';
import Sidebar from './components/Sidebar';
import NavBar from '../components/NavBar';
import ServiceGallery from './components/ServiceGallery';

const ServiceDetails = () => {


    const { id } = useParams();
    const service = contentData.services.find(service => service.id === id);
  
    if (!service) {
      return <h2>Service not found</h2>;
    }


    return (
        

        <div>
                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                <h1>{service.title}</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`/services`}>Services</a></li>
                                        <li>{service.title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 order-1 order-lg-2">
                            <div className="service-details">
                                {/* service gallery */}
                                <ServiceGallery/>

                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    <h2>{service.title}</h2>
                                    <p>{service.description}</p>
                                    </div> 
                                     <div>
         <ul>
             {service.serviceType.map((type, index) => (
               <li key={index}>
                <div style={{justifyContent: "space-evenly"}}>

                 <h3 style={{paddingBottom: "10px", paddingTop: "25px"}}>{type.name}</h3>
                 <p>{type.content}</p>
                </div>
               </li>
             ))}
           </ul>
       
         </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-12 order-2 order-lg-1">
                                <Sidebar />
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>

            

                {/* Footer */}
                <Footer />

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
      );
    };

export default ServiceDetails