import React, {Component} from 'react';

class BlogGrid extends Component{
    render(){
        let data = [
            {img:'1.jpg', date:'May 25, 2024', title: 'Wage Increases and Labor Disputes', postExcerpt: 'The Construction, Forestry, Maritime, Mining and Energy Union (CFMEU) has been active in negotiating better wages and conditions for its members. Recently, construction workers secured a pay increase, receiving an immediate 6% raise followed by an additional 1.5% at the start of 2024, totaling a 7.59% increase (CFMEU Construction & General).The CFMEU has also been involved in legal actions...', link: "wage-increases-and-labor-disputes", id: "wage-increases-and-labor-disputes"},
            {img:'2.jpg', date:'May 26, 2024', title: 'Federal Mandates and Project Labor Agreements:', postExcerpt: 'President Joe Biden has reinforced his pro-union stance by mandating project labor agreements (PLAs) for large-scale federal construction projects costing over $35 million. This executive order aims to secure higher wages and ensure union jobs, impacting an estimated $262 billion in federal construction contracts (Courthouse News)', link: "federal-mandates-project-labor-agreement", id: "wage-increases-and-labor -disputes"},
            {img:'3.jpg', date:'May 27, 2024', title: 'Union Membership and Workforce Shortage:', postExcerpt: 'The construction sector continues to face a critical labor shortage, needing an estimated 546,000 additional workers in 2024 to meet demand. Despite this, union membership within the construction industry has remained relatively stable, with around 12.6% of workers being union members as of the latest reports (NABTU) (Bureau of Labor Statistics).', link: "union-membership-workforce-shortage", id: 'union-membership-workforce-shortage'}
      
        ];

        let DataList = data.map((val, i) => {
            return(
                <div className="col-lg-4" key={i}>
                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                        <div className="blog-post-slider__image section-space--bottom--30">
                            <a href={`blog/${val.link}`}><img src={`assets/img/blog/${val.img}`} className="img-fluid" alt="" /></a>
                            </div>
                            <div className="blog-post-slider__content">
                            <p className="post-date"> {val.date}</p>
                            <h3 className="post-title">
                                <a href={`blog/${val.link}`}>{val.title}</a>
                            </h3>
                            <p className="post-excerpt">{val.postExcerpt}</p>
                            <a href={`blog/${val.link}`} className="see-more-link">SEE MORE</a>
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                {/*====================  blog grid area ====================*/}
                <div className={`blog-grid-area section-space--inner--120 ${this.props.background}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title-area text-center">
                                <h2 className="section-title section-space--bottom--50">News Blog <span className="title-icon" /></h2>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="blog-grid-wrapper">
                                <div className="row">
                                    {DataList}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of blog grid area  ====================*/}

            </div>
        )
    }
}


export default BlogGrid;