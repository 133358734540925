import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';


class ServiceGridSlider extends Component{
    render(){
        SwiperCore.use([Pagination, Navigation, Autoplay]);
        const params = {
            slidesPerView : 3,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
            spaceBetween : 30,
            autoplay: {
                delay: 3000,
            },
            // Responsive breakpoints
            breakpoints: {
                1499:{
                    slidesPerView : 3
                },

                991:{
                    slidesPerView : 3
                },

                767:{
                    slidesPerView : 2
                },

                640:{
                    slidesPerView : 1
                },
                320: {
                    slidesPerView : 1
                }
            }

        }
        let data = [
            // {img: 'service1.jpg', serviceTitle: 'Land Minning', serviceExcerpt: 'Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor', serviceUrl: 'service-details-left-sidebar'},
            // {img: 'service2.jpg', serviceTitle: 'Work Management', serviceExcerpt: 'Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor', serviceUrl: 'service-details-left-sidebar'},
            // {img: 'service3.jpg', serviceTitle: 'Material Engineering', serviceExcerpt: 'Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor', serviceUrl: 'service-details-left-sidebar'},
            // {img: 'service1.jpg', serviceTitle: 'Power and Energy', serviceExcerpt: 'Lorem ipsum dolor sit amet consect adipisi elit sed do eiusm tempor', serviceUrl: 'service-details-left-sidebar'},
            {pageLink: 'project-management', img: 'project-management.jpg', iconClass: 'fi fi-ts-workflow-setting', serviceTitle: 'Project Management', serviceSubtitle: 'Comprehensive project planning, scheduling, budgeting, and coordination to ensure successful project delivery from start to finish.'},
            {pageLink: 'construction-management', img: 'service2.jpg', iconClass: 'fi fi-ts-builder', serviceTitle: 'Construction Management', serviceSubtitle: 'Oversight of construction activities, subcontractor management, quality control, safety protocols, and compliance with regulations and standards.'},
            {pageLink: 'design-build-services', img: 'service3.jpg', iconClass: 'fi fi-ts-build', serviceTitle: 'Design-Build Services', serviceSubtitle: 'Integrated design and construction services to streamline project delivery, improve communication, and optimize cost and schedule efficiencies.'},
            {pageLink: 'pre-conconstruction-services', img: 'service-1.jpg', iconClass: 'fi fi-ts-truck-tow', serviceTitle: 'Pre-construction Services', serviceSubtitle: 'Feasibility studies, site analysis, budget estimation, value engineering, and planning services to lay the groundwork for successful project execution.'},
            {pageLink: 'renovation-remodeling', img: 'service-2.jpg', iconClass: 'fi fi-ts-digging', serviceTitle: 'Renovation and Remodeling', serviceSubtitle: 'Renovation, refurbishment, and remodeling services for existing structures, including structural modifications, interior upgrades, and energy-efficient improvements.'},
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide" key={i}>
                    <div className="service-grid-item service-grid-item--style2">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`services/${val.pageLink}`}>
                            <img src={`assets/img/service/${val.img}`} className="img-fluid" alt="" />
                        </a>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`services/${val.pageLink}`}>{val.serviceTitle}</a>
                        </h3>
                        <p className="subtitle">{val.serviceSubtitle}</p>
                        <a href={`services/${val.pageLink}`} className="see-more-link">SEE MORE</a>
                    </div>
                    </div>
                </div>
            )
        });
        return(
            <div>
                {/*====================  project grid slider area ====================*/}
                <div className="service-slider-title-area grey-bg section-space--inner--top--120 section-space--inner--bottom--285">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="section-title-area text-center">
                            <h2 className="section-title mb-0">Construction Services <span className="title-icon" /></h2>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="service-grid-slider-area">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-slider">
                            <div className="service-slider__container service-slider__container--style2">
                                <Swiper {...params}>
                                    {DataList}
                                </Swiper>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*====================  End of project grid slider area  ====================*/}

            </div>
        )
    }
}


export default ServiceGridSlider;