export const contentData = {
    "services": [
        {
            "id": "project-management",
            "title": "Project Management",
            "description": "We ensure the successful completion of constructions projects, from inception to completion. These services are essential for coordinating all aspects of a project, ensuring that it is delivered on time, within budget, and to the required quality standards. Here’s a detailed overview of the various facets involved in how we offer our project management services.",
            "serviceType": [
                {
                    "name": "Project Planning and Scheduling",
                    "content": "Effective project management begins with comprehensive planning and scheduling which involves clearly outlining the objectives, deliverables, and specifications of the project. Creating a detailed roadmap that includes timelines, milestones, and resource allocation while using tools like Gantt charts or critical path methods to develop a project schedule that identifies the sequence of activities and critical deadlines."
                },
                {
                    "name": "Resource Management",
                    "content": "Efficient allocation and management of resources are vital for project management this involves ensuring timely procurement and availability of materials and machinery necessary for the project while also recruiting, training and managing the workforce."
                },
                {
                    "name": "Quality Management",
                    "content": "Maintaining high quality standards throughout any project lifecycle we embark on which includes implementing processes and standards to prevent defects also regularly inspecting the work being commenced, ensuring it meets the required standards and specification."
                },
                {
                    "name": "Risk Management",
                    "content": "Identifying and mitigating risks to prevent disruption which involves analyzing potential risks related to safety, financials, and project delays also developing and implementing plans to minimize identified risks."
                }
            ]
        },
        {
            "id": "construction-management",
            "title": "Construction Management",
            "description": "Overseeing and guiding construction projects from inception to completion. The scope of these services is broad and encompasses various stages of a construction process, here’s a detailed overview of what we offer:",
            "serviceType": [
                {
                    "name": "Feasibility Studies",
                    "content": "We conduct feasibility studies to evaluate the projects viability, including cost estimates, site analysis, and risk assessments."
                },
                {
                    "name": "Design and Pre-construction",
                    "content": "Collaborating with architects and engineers to ensure the design align with the client vision, budget, and regulatory requirement also managing the process of obtaining all necessary permits and approvals from local authorities, ensuring compliance with building codes and regulations."
                },
                {
                    "name": "Construction Phase Management",
                    "content": "Providing on site supervision to monitor the daily progress of construction activities, ensuring adherence to plans and specifications also implementing stringent quality control measures to ensure that the construction work meets the highest standards."
                },
                {
                    "name": "Post-construction Services",
                    "content": "Coordinating final inspections, ensuring all work is completed to the client’s satisfaction and all regulatory requirements are met also managing any warranty claims and ensuring prompt resolution of any defects or issues that arise."
                }
            ]
        },
        {
            "id": "design-build-services",
            "title": "Design-Build Services",
            "description": "We offer a streamlined approach to delivering construction projects by integrating design and construction phases under a single contract. This method contrasts with traditional project delivery, where design and construction services are contracted separately. An overview of said service is as follows:",
            "serviceType": [
                {
                    "name": "Unified Team",
                    "content": "Forming a cohesive team that includes architects, engineers, and construction professionals. This team collaborates from the projects inception to its completion, ensuring seamless communication and coordination also with a single entity responsible for both design and construction, clients have a clear point of contact and accountability, reducing the risk of conflicts and ensuring consistent quality throughout the project."
                },
                {
                    "name": "Integrated Project Delivery",
                    "content": "Design-build encourages early involvement of the construction team during the design phase. This allows for practical input on materials, construction methods, and cost estimates, enhancing the feasibility and efficiency of the design."
                },
                {
                    "name": "Collaborative Design Development",
                    "content": "With continuous feedback loops we keep clients actively involved in the design process ensuring their vision and requirements are met. Regular updates and consultations keep clients informed and engaged also the design-build team can quickly adapt to changes and unexpected challenges, offering flexible solutions that can maintain project objectives."
                },
                {
                    "name": "Innovative Solutions",
                    "content": "Leveraging combined expertise to introduce innovative design and construction techniques. This approach fosters creativity and the adoption of new technologies, materials and sustainable practices."
                }
            ]
        },
        {
            "id": "general-contracting",
            "title": "General Contracting",
            "description": "Managing and overseeing construction projects from start to finish. Our services include project planning, budgeting, scheduling, procuring materials, hiring and coordinating subcontractors, ensuring compliance with safety and building regulations, and maintaining communication with clients. An overview is as follows:",
            "serviceType": [
                {
                    "name": "Project Planning and Design Coordination",
                    "content": "Pre construction services which include feasibility studies, budgeting, and cost estimation working closely with architects and engineers during the design phase to ensure the project is viable and aligns with the clients goals also handling the necessary permitting and regulatory approvals, ensuring compliance with local, state, and federal regulations."
                },
                {
                    "name": "Scheduling",
                    "content": "Creating and maintaining a detailed project schedule is crucial coordinating with various stakeholders to ensure timely progression through each phase of construction. Also managing the allocation of resources, including labor, materials, and equipment to optimize efficiency and minimize delays."
                },
                {
                    "name": "Procurement and Subcontracting",
                    "content": "Soliciting bids from subcontractors and suppliers, evaluating proposals and negotiating contracts. This includes selecting specialized trades such as electricians, plumbers, and HVAC installers. Ensuring timely delivery of materials and equipment, managing supplier relationships, and handling logistics are key responsibilities."
                },
                {
                    "name": "Construction Execution",
                    "content": "Providing on site supervision, ensuring that work is performed according to the project plans and specifications. Monitoring quality control and adherence to safety protocols also addressing any issues that arise during construction such as unforeseen obstacles or design changes this involves adjusting schedules to keep the project on track."
                }
            ]
        },
        {
            "id": "pre-conconstruction-services",
            "title": "Pre-Construction Services",
            "description": "We ensure a smooth transition from concept to actual building as this is a critical phase in any construction project. These services involve extensive planning, coordination, and evaluation before the commencement of physical construction. Here’s an overview of the services rendered:",
            "serviceType": [
                {
                    "name": "Project Feasibility and Analysis",
                    "content": "Assessing the site conditions, including topography, soil quality, environmental impact, and zoning regulations, developing and evaluating the viability of the project considering financial, technical, and operational aspects."
                },
                {
                    "name": "Design and Planning",
                    "content": "Our architects and designers would create initial design concepts that align with the client’s vision while developing schematics designs that outline the projects scope, scale and overall functionality."
                },
                {
                    "name": "Project Scheduling",
                    "content": "Creating a detailed project schedule that outlines all major milestones and deadlines while developing plans for project phases to ensure efficient workflow and resource allocation."
                },
                {
                    "name": "Client Communication and Coordination",
                    "content": "Providing regular updates to clients regarding project progress, design adjustments, and budget considerations also conducting meetings with all stakeholders to ensure alignment and address any concerns promptly."
                }
            ]
        },
        {
            "id": "renovation-remodeling",
            "title": "Renovation and Remodeling",
            "description": "Providing solutions to update and enhance existing structures, whether they are residential, commercial, or industrial properties. These services encompass a wide range of activities designed to improve the functionality, aesthetics, and value of the property. Here’s a broad overview of what such services comprise of:",
            "serviceType": [
                {
                    "name": "Design and Architecture Services",
                    "content": "Offering design and architectural services to create detailed plans and blueprints. This includes selecting materials, designing structural changes, and ensuring the plans meet local building codes and regulations. Clients can visualize the proposed changes through 3D renderings or virtual walkthroughs."
                },
                {
                    "name": "Demolition and Site Preparation",
                    "content": "Existing structures or parts of the property may need to be demolished or removed. This could include tearing down walls, removing outdated fixtures, and clearing debris. Site preparation ensures that the space is ready for the new construction work."
                },
                {
                    "name": "Construction and Remodeling",
                    "content": "This process involves the actual construction work which can range from minor updates such as repainting walls and installing new flooring, to major renovations like adding new rooms, reconfiguring layouts, or updating electrical and plumbing systems. Skilled laborers and craftsmen execute these tasks ensuring high quality workmanship."
                },
                {
                    "name": "Specialized Installations",
                    "content": "Depending on the scope of the project this might be required and includes installing new HVAC systems, smart home technologies, custom cabinetry, or unique architectural features. We coordinate these installations often working with specialized subcontractors."
                }
            ]
        },
        {
            "id": "consulting-advisory-services",
            "title": "Consulting and Advisory Services",
            "description": "We offer consulting and advisory services to assist clients in various stages of a construction project, from initial planning and design to completion and maintenance. These services are used to ensure the success of the project and optimize outcomes in the project execution. Here’s a broad overview of these services:",
            "serviceType": [
                {
                    "name": "Risk Analysis",
                    "content": "Evaluating the technical and financial viability of the project while also understanding the client’s requirements, goals, and constraints. Identifying potential risks and developing mitigation strategies."

                },
                {
                    "name": "Sustainability Consulting",
                    "content": "Analyzing functions and materials to reduce costs without compromising quality while also advising on green building practices and sustainable materials to minimize environmental impact."

                },
                {
                    "name": "Safety Management",
                    "content": "Implementing quality control measures to maintain high standards of construction while also advising on safety protocols and compliance with health and safety regulations."

                },
            ],
        },
        {
            "id": "facility-management",
            "title": "Facilities Management",
            "description": "This is a critical aspect of construction extending beyond the mere completion of building projects to ensure ongoing operational efficiency, safety, and comfort of the built environment. Here’s an overview of the service we render:",
            "serviceType": [
                {
                    "name": "Integrated Services Approach",
                    "content": "Routine, preventive and reactive maintenance of building systems to ensure they operate efficiently, implementing and managing security systems including surveillance, access control, and on site security personnel."

                },
                {
                    "name": "Energy Management: ",
                    "content": "Evaluating energy use patterns to identify savings opportunities, implementing green building practices, renewable energy solutions and sustainable waste management."

                },
                {
                    "name": "Technology Integration: ",
                    "content": " Integrating automated systems for monitoring and controlling building operations, managing maintenance schedules, tracking work orders, and storing facility data."

                },
                {
                    "name": "Sustainability and Environmental Management",
                    "content": "Implementing recycling and waste minimization strategies with the efficient use of water resources and managing wastewater. "

                },
            ],
        },
        {
            "id": "janitorial-services",
            "title": "Janitorial Services",
            "description": "Our team is well known for excellence in janitorial and cleaning services. We have a customized, task specific system for each and every client site!",
            "serviceType": [
                {
                    "name": "Our Janitorial Services includes:",
                    "content": "Our janitorial services includes commercial janitorial, Post construction and pre move cleaning, carpet care and Maintenance, garage cleaning, window washing ground maintenance, Porter/Day Maid services"

                },
                
            ],
        },
    ],
   "blogPost": [
        {img:'1.jpg', date:'May 25, 2024', title: 'Wage Increases and Labor Disputes', postExcerpt: 'The Construction, Forestry, Maritime, Mining and Energy Union (CFMEU) has been active in negotiating better wages and conditions for its members. Recently, construction workers secured a pay increase, receiving an immediate 6% raise followed by an additional 1.5% at the start of 2024, totaling a 7.59% increase (CFMEU Construction & General).', link: "wage-increases-and-labor-disputes", id: "wage-increases-and-labor-disputes"},
        {img:'2.jpg', date:'May 26, 2024', title: 'Federal Mandates and Project Labor Agreements:', postExcerpt: 'President Joe Biden has reinforced his pro-union stance by mandating project labor agreements (PLAs) for large-scale federal construction projects costing over $35 million. This executive order aims to secure higher wages and ensure union jobs, impacting an estimated $262 billion in federal construction contracts (Courthouse News)', link: "federal-mandates-project-labor-agreement", id: "wage-increases-and-labor -disputes"},
        {img:'3.jpg', date:'May 27, 2024', title: 'Union Membership and Workforce Shortage:', postExcerpt: 'The construction sector continues to face a critical labor shortage, needing an estimated 546,000 additional workers in 2024 to meet demand. Despite this, union membership within the construction industry has remained relatively stable, with around 12.6% of workers being union members as of the latest reports (NABTU) (Bureau of Labor Statistics).', link: "union-membership-workforce-shortage", id: 'union-membership-workforce-shortage'}
  
    ]
}








