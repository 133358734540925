import React, {Component} from 'react';
import './index.scss';
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import About from './pages/About';
import Services from './service/Services';
import ServiceDetailsLeftSidebar from './service/ServiceDetailsLeftSidebar';
import ServiceDetailsRightSidebar from './service/ServiceDetailsRightSidebar';
import Projects from './project/Projects';
import ProjectDetails from './project/ProjectDetails';
import BlogLeftSidebar from './blog/BlogLeftSidebar';
import BlogRightSidebar from './blog/BlogRightSidebar';
import BlogDetailsLeftSidebar from './blog/BlogDetailsLeftSidebar';
import BlogDetailsRightSidebar from './blog/BlogDetailsRightSidebar';
import Contact from './pages/Contact';
import PageNotFound from './pages/404';
import NoMAtch from './pages/404';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
// import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import ServiceDetails from './service/ServiceDetails';
import BlogPost from './blog/BlogPost';


// SwiperCore.use([Pagination]);

class App extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`/`} component={HomeTwo}/>
                    <Route exact path={`/home-one`} component={HomeOne}/>
                    <Route exact path={`/about-us`} component={About}/>
                    <Route exact path={`/services`} component={Services}/>
                    <Route exact path={`/service-details-left-sidebar`} component={ServiceDetailsLeftSidebar}/>
                    <Route path={`/services/:id`} component={ServiceDetails }/>
                    <Route path={`/blog/:id`} component={BlogPost }/>
                    <Route exact path={`/service-details-right-sidebar`} component={ServiceDetailsRightSidebar}/>
                    <Route exact path={`/projects`} component={Projects}/>
                    <Route exact path={`/project-details`} component={ProjectDetails}/>
                    <Route exact path={`/blog`} component={BlogLeftSidebar}/>
                    <Route exact path={`/blog-right-sidebar`} component={BlogRightSidebar}/>
                    <Route exact path={`/blog-details-left-sidebar`} component={BlogDetailsLeftSidebar}/>
                    <Route exact path={`/blog-details-right-sidebar`} component={BlogDetailsRightSidebar}/>
                    <Route exact path={`/contact-us`} component={Contact}/>
                    <Route exact path={`/404`} component={PageNotFound}/>
                    <Route component={NoMAtch} />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default App;
