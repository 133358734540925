import React, { Component } from 'react';

class BlogPostContent extends Component{
    constructor(props){
        super(props);
        state:{}
    }
    render(){
            
        /* post tag */
        
        let tagData = [
            {tagLink: "blog-left-sidebar", tagName: "Renovation"},
            {tagLink: "blog-left-sidebar", tagName: "Architecture"},
            {tagLink: "blog-left-sidebar", tagName: "Concrete"}
        ];

        let tagDataList = tagData.map((val, i)=>{
            return(
                <li key={i}><a href={`/${val.tagLink}`}>{val.tagName}</a></li>
            )
        });

        return(
            <div>
                <div className="row">
                    <div className="blog-details col-12">
                    <div className="blog-inner">
                        <div className="media"><div className="image"><img src="assets/img/blog/blog-details-1.jpg" alt="" /></div></div>
                        <div className="content">
                        <ul className="meta">
                            <li>By <a href="blog-left-sidebar">admin</a></li>
                            <li>{this.props.post.date}</li>
                            <li><a href="/">3 Comment</a></li>
                        </ul>
                        <h2 className="title">{this.props.post.title}</h2>
                        <div className="desc section-space--bottom--30">
                            <p>{this.props.post.postExcerpt}</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam explicabo iusto suscipit dolore repellendus odit laborum, cupiditate unde nesciunt eveniet temporibus autem adipisci earum at error aspernatur neque minima doloribus?</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis voluptate repellat quam exercitationem excepturi enim et blanditiis amet minima itaque, doloribus cumque labore. Asperiores nemo odio sed molestias harum laborum quia enim, delectus repellendus consequuntur unde magnam, ipsam possimus vero, quam dolore adipisci. Rerum, vitae!</p> */}
                        </div>
                        <ul className="tags">
                            <li><i className="fa fa-tags" /></li>
                            {tagDataList}
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-12 section-space--top--60">
                    <div className="comment-wrapper">
                        <h3>Leave Your Comment</h3>
                        <div className="comment-form">
                        <form action="#">
                            <div className="row row-10">
                            <div className="col-md-6 col-12 section-space--bottom--20"><input type="text" placeholder="Your Name" /></div>
                            <div className="col-md-6 col-12 section-space--bottom--20"><input type="email" placeholder="Your Email" /></div>
                            <div className="col-12"><textarea placeholder="Your Message" defaultValue={""} /></div>
                            <div className="col-12"><input type="submit" defaultValue="Send Comment" /></div>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogPostContent;