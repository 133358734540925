import React, { Component } from 'react';
import MobileMenu from './MobileMenu'; // Ensure the correct path to the MobileMenu component

class NavBar extends Component {
    state = {
        isMobileMenuVisible: false
    };

    toggleMobileMenu = () => {
        this.setState(prevState => ({ isMobileMenuVisible: !prevState.isMobileMenuVisible }));
    };

    closeMobileMenu = () => {
        this.setState({ isMobileMenuVisible: false });
    };

    render() {
        const { isMobileMenuVisible } = this.state;

        return (
            <div>
                <div className="header-area header-sticky header-sticky--default">
                    <div className="header-area__desktop header-area__desktop--default">
                        <div className="header-top-bar">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <div className="top-bar-left-wrapper">
                                            <div className="social-links social-links--white-topbar d-inline-block">
                                                <ul>
                                                    <li><a href="//facebook.com"><i className="zmdi zmdi-facebook" /></a></li>
                                                    <li><a href="//twitter.com"><i className="zmdi zmdi-twitter" /></a></li>
                                                    <li><a href="//vimeo.com"><i className="zmdi zmdi-vimeo" /></a></li>
                                                    <li><a href="//linkedin.com"><i className="zmdi zmdi-linkedin-box" /></a></li>
                                                    <li><a href="//skype.com"><i className="zmdi zmdi-skype" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="top-bar-right-wrapper">
                                            <a href={`/contact-us`} className="ht-btn ht-btn--default d-inline-block">GET A QUOTE</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header-info-area">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="header-info-wrapper align-items-center">
                                            <div className="logo">
                                                <a href={`/`}>
                                                    <img src="/assets/img/logo/logo.png" className="img-fluid" alt="Logo" />
                                                </a>
                                            </div>
                                            <div className="header-contact-info">
                                                <div className="header-info-single-item">
                                                    <div className="header-info-single-item__icon">
                                                        <i className="zmdi zmdi-smartphone-android" />
                                                    </div>
                                                    <div className="header-info-single-item__content">
                                                        <h6 className="header-info-single-item__title">Phone</h6>
                                                        <p className="header-info-single-item__subtitle">(001) 503-984-7493</p>
                                                    </div>
                                                </div>
                                                <div className="header-info-single-item">
                                                    <div className="header-info-single-item__icon">
                                                        <i className="zmdi zmdi-home" />
                                                    </div>
                                                    <div className="header-info-single-item__content">
                                                        <h6 className="header-info-single-item__title">Address</h6>
                                                        <p className="header-info-single-item__subtitle">8112, Leland Road Manassas, Virginia 20111, USA</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mobile-navigation-icon" id="mobile-menu-trigger" onClick={this.toggleMobileMenu}>
                                                <i className="ion-android-menu" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header-navigation-area default-bg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="header-navigation header-navigation--header-default position-relative">
                                            <div className="header-navigation__nav position-static">
                                                <nav>
                                                    <ul>
                                                        <li><a href={`/`}>HOME</a></li>
                                                        <li><a href={`/about-us`}>ABOUT</a></li>
                                                        <li><a href={`/services`}>SERVICES</a></li>
                                                        <li><a href={`/blog`}>BLOG</a></li>
                                                        <li><a href={`/contact-us`}>CONTACT</a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MobileMenu isVisible={isMobileMenuVisible} onClose={this.closeMobileMenu} />
            </div>
        );
    }
}

export default NavBar;
