import React from 'react'
import Sidebar from './components/Sidebar';
import BlogPostContent from './components/BlogPostContent';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import NavBar from '../components/NavBar';
import { useParams } from 'react-router-dom';
import { contentData } from '../contentData';

const BlogPost = () => {
    const { id } = useParams();
    const post = contentData.blogPost.find(post => post.id === id);
  
    if (!post) {
      return <h2>Post not found</h2>;
    }


  return (
     <div>
                {/* Navigation bar */}
                <NavBar/>
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>{post.title}</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li><a href={`/blog-right-sidebar`}>Blog</a></li>
                                        <li>{post.title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  blog details page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Blog section start*/}
                <div className="blog-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12 order-1">
                            {/* blog post content */}
                            <BlogPostContent post={post} />
                        </div>
                        <div className="col-lg-4 col-12 order-2">
                            {/* blog sidebar */}
                            <Sidebar />
                        </div>
                    </div>
                    </div>
                </div>
                {/*Blog section end*/}
                </div>


                {/*====================  End of blog details page content  ====================*/}

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
  )
}

export default BlogPost