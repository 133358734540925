import React, {Component} from 'react';

class Footer extends Component{
    render(){
        return(
            <div>
                {/*====================  footer area ====================*/}
                <div className="footer-area dark-bg">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-content-wrapper section-space--inner--100">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-12">
                                {/* footer intro wrapper */}
                                <div className="footer-intro-wrapper">
                                    <div className="footer-logo">
                                    <a href={`/`}>
                                        <img src="/assets/img/logo/logo-light.png" className="img-fluid" alt="Kigpin LLC" />
                                    </a>
                                    </div>
                                    <div className="footer-desc">
                                    We strive to lead the industry by seamlessly integrating project management expertise with cutting-edge construction practices, delivering exceptional results that exceed expectations and inspire confidence. 
                                    </div>
                                </div>
                                </div>
                                <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                {/* footer widget */}
                                <div className="footer-widget">
                                    <h4 className="footer-widget__title">INFORMATION</h4>
                                    <ul className="footer-widget__navigation">
                                    <li><a href={`/`}>Home</a></li>
                                    
                                    <li><a href={`/about-us`}>About Us</a></li>
                                    <li><a href={`/`}>Careers</a></li>
                                    <li><a href={`/contact-us`}>Contact Us</a></li>
                                    </ul>
                                </div>
                                </div>
                                <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                {/* footer widget */}
                                <div className="footer-widget">
                                    <h4 className="footer-widget__title">USEFUL LINKS</h4>
                                    <ul className="footer-widget__navigation">
                                    <li><a href={`/services`}>Our Services</a></li>
                                    
                                    <li><a href={`/blog`}>Blog</a></li>
                                    <li><a href={`/`}>Privacy Policy</a></li>
                                    </ul>
                                </div>
                                </div>
                                <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                {/* footer widget */}
                                <div className="footer-widget mb-0">
                                    <h4 className="footer-widget__title">CONTACT US</h4>
                                    <div className="footer-widget__content">
                                    <p className="address">8112, Leland Road Manassas, Virginia 20111, USA.</p>
                                    <ul className="contact-details">
                                        <li><span>Phone:</span>(01) 503-984-7493</li>
                                        
                                        <li><span>Email:</span>info@thekingpinllc.com</li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                        <div className="footer-copyright-wrapper">
                        <div className="footer-copyright text-center">
                        Copyright © 2024. Kingpin Construction LLC. All right reserved.
                        </div>
                    </div>
                </div>
                {/*====================  End of footer area  ====================*/}
                {/*====================  scroll top ====================*/}
                <button className="scroll-top" id="scroll-top">
                    <i className="ion-android-arrow-up" />
                </button>
                {/*====================  End of scroll top  ====================*/}
            </div>
        )
    }
}


export default Footer;