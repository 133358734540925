import React, { Component } from 'react';

class Sidebar extends Component{
    render(){

        /* sidebar Quick links */

        let categoryData = [
            {categoryLink: "", categoryName: "Home"},
            {categoryLink: "about-us", categoryName: "About Us"},
            {categoryLink: "contact-us", categoryName: "Contact us"},
            {categoryLink: "services", categoryName: "Services"},
            {categoryLink: "blog", categoryName: "Blog"},
            
        ];

        let categoryDataList = categoryData.map((val, i)=>{
            return(
                <li key={i}><a href={`/${val.categoryLink}`}>{val.categoryName}</a></li>
            )
        });
        
        /* sidebar popular post */
        
        // let popularPostData = [
        //     {postImage: 'sidebar-blog-1.jpg', postTitle: 'What are Groundworkers and what do they do?', postLink: 'blog-details-left-sidebar', postDate: '30 October 2019'},
        //     {postImage: 'sidebar-blog-2.jpg', postTitle: 'What are Groundworkers and what do they do?', postLink: 'blog-details-left-sidebar', postDate: '30 October 2019'},
        //     {postImage: 'sidebar-blog-3.jpg', postTitle: 'What are Groundworkers and what do they do?', postLink: 'blog-details-left-sidebar', postDate: '30 October 2019'}
        // ];

        // let popularPostDataList = popularPostData.map((val, i)=>{
        //     return(
        //         <div className="sidebar-blog" key={i}>
        //         <a href="blog-details-left-sidebar.html" className="image"><img src={`assets/img/blog/${val.postImage}`} alt="" /></a>
        //             <div className="content">
        //                 <h5><a href={`/${val.postLink}`}>What are Groundworkers and what do they do?</a></h5>
        //                 <span>{val.postDate}</span>
        //             </div>
        //         </div>
        //     )
        // });

        
        /* sidebar tag */
        
        let tagData = [
            {tagLink: "", tagName: "Renovation"},
            {tagLink: "", tagName: "Architecture"},
            {tagLink: "", tagName: "Concrete"},
            {tagLink: "", tagName: "Flooring"},
            {tagLink: "", tagName: "Consulting"},
            {tagLink: "", tagName: "Interior"},
            {tagLink: "", tagName: "Planning"}
        ];

        let tagDataList = tagData.map((val, i)=>{
            return(
                <li key={i}><a href={`/${val.tagLink}`}>{val.tagName}</a></li>
            )
        });

        return(
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">Search</h3>
                        <div className="sidebar-search">
                            <form action="#">
                                <input type="text" placeholder="Search" />
                                <button><i className="ion-ios-search" /></button>
                            </form>
                        </div>
                    </div>
                    <div className="sidebar">
                        <h3 className="sidebar-title">Quick Links</h3>
                        <ul className="sidebar-list">
                            {categoryDataList}
                        </ul>
                    </div>
                    <div className="sidebar">
                        <h3 className="sidebar-title">Popular Tags</h3>
                        <ul className="sidebar-tag">
                            {tagDataList}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default Sidebar;