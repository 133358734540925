import React, {Component} from 'react';
import NavBar from '../components/NavBar';
// import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class Services extends Component{
    render(){

        let data = [
            {pageLink: 'project-management', img: 'project-management.jpg', iconClass: 'fi fi-ts-workflow-setting', serviceTitle: 'Project Management', serviceSubtitle: 'Comprehensive project planning, scheduling, budgeting, and coordination to ensure successful project delivery from start to finish.'},
            {pageLink: 'construction-management', img: 'service2.jpg', iconClass: 'fi fi-ts-builder', serviceTitle: 'Construction Management', serviceSubtitle: 'Oversight of construction activities, subcontractor management, quality control, safety protocols, and compliance with regulations and standards.'},
            {pageLink: 'design-build-services', img: 'service3.jpg', iconClass: 'fi fi-ts-build', serviceTitle: 'Design-Build Services', serviceSubtitle: 'Integrated design and construction services to streamline project delivery, improve communication, and optimize cost and schedule efficiencies.'},
            {pageLink: 'general-contracting', img: 'general-contracting.jpg', iconClass: 'fi fi-ts-user-helmet-safety', serviceTitle: 'General Contracting', serviceSubtitle: 'Management of construction projects as the primary contractor, including subcontractor procurement, construction supervision, and quality assurance.'},
            {pageLink: 'pre-conconstruction-services', img: 'service-1.jpg', iconClass: 'fi fi-ts-truck-tow', serviceTitle: 'Pre-construction Services', serviceSubtitle: 'Feasibility studies, site analysis, budget estimation, value engineering, and planning services to lay the groundwork for successful project execution.'},
            {pageLink: 'renovation-remodeling', img: 'service-2.jpg', iconClass: 'fi fi-ts-digging', serviceTitle: 'Renovation and Remodeling', serviceSubtitle: 'Renovation, refurbishment, and remodeling services for existing structures, including structural modifications, interior upgrades, and energy-efficient improvements.'},
            {pageLink: 'consulting-advisory-services', img: 'consulting_services.jpg', iconClass: 'fi fi-ts-department', serviceTitle: 'Consulting and Advisory Services', serviceSubtitle: 'Expertise and guidance in project feasibility assessment, risk management, regulatory compliance, sustainability strategies, and project optimization.'},
            {pageLink: 'facility-management', img: 'facility_management.jpg', iconClass: "fi fi-rr-practice", serviceTitle: 'Facilities Management', serviceSubtitle: 'FPost-construction services such as maintenance, repairs, facility upgrades, and ongoing support to ensure long-term operational efficiency and asset performance.'},
            {pageLink: 'janitorial-services', img: 'JanitorialServices.jpg', iconClass: 'fi fi-rs-clean', serviceTitle: 'Janitorial Services', serviceSubtitle: 'Our team is well known for excellence in janitorial and cleaning services. We have a customized, task specific system for each and every client site.'}
        ];

        let Datalist = data.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`services/${val.pageLink}`}>
                            <img src={`assets/img/service/${val.img}`} className="img-fluid" alt="Service Grid" />
                        </a>
                        </div>
                        <div className="icon">
                        <i class={val.iconClass} />
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`services/${val.pageLink}`}>{val.serviceTitle}</a>
                        </h3>
                        <p className="subtitle">{val.serviceSubtitle}</p>
                        <a href={`services/${val.pageLink}`} className="see-more-link">SEE MORE</a>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>

                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Service</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>Service</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Service section start*/}
                <div className="service-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-item-wrapper">
                            <div className="row">
                                {Datalist}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

                {/* Brand logo */}
                {/* <BrandLogoSlider background = "grey-bg" /> */}

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}

export default Services;