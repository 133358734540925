import React, { Component } from 'react';
import { FaUsers, FaRegLightbulb, FaShieldAlt, FaHandsHelping, FaCheck, FaHandHoldingHeart, FaTrophy, FaPeopleCarry} from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";



class FeatureIcon extends Component {
    render() {
        let data = [
            { featureIcon: "funfact-clients.png", featureTitle: "Customer Centric", featureDescription: "Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Officia odio eum corporis consequatur." },
            { featureIcon: "feature-2.png", featureTitle: "Integrity", featureDescription: "Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Officia odio eum corporis consequatur." },
            { featureIcon: "feature-5.png", featureTitle: "Ownership", featureDescription: "Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Officia odio eum corporis consequatur." },
            { featureIcon: "feature-7.png", featureTitle: "Best Quality", featureDescription: "Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Officia odio eum corporis consequatur." },
            { featureIcon: "feature-1.png", featureTitle: "Innovation", featureDescription: "Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Officia odio eum corporis consequatur." },
            { featureIcon: "feature-6.png", featureTitle: "Community Engagement", featureDescription: "Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Officia odio eum corporis consequatur." },
            { featureIcon: "feature-4.png", featureTitle: "Deliver result", featureDescription: "Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Officia odio eum corporis consequatur." },
            { featureIcon: "feature-3.png", featureTitle: "Collaboration", featureDescription: "Lorem ipsum dolor sit amet, consec tetur adipisicing elit. Officia odio eum corporis consequatur." }
        ];

        // Map image names to react-icons components
        const iconMap = {
            "funfact-clients.png": FaUsers,
            "feature-1.png": FaRegLightbulb,
            "feature-2.png": FaShieldAlt,
            "feature-3.png": FaHandsHelping,
            "feature-4.png": FaTrophy,
            "feature-5.png": FaCheck,
            "feature-6.png": FaHandHoldingHeart,
            "feature-7.png": HiBadgeCheck,
            // Add more mappings as needed
        };

        let Datalist = data.map((val, i) => {
            const IconComponent = iconMap[val.featureIcon] || FaCheck; // Default icon if not found in map

            return (
                <div className="single-fact col-md-3 col-6 section-space--bottom--30" key={i}>
                    <div className="team" style={{ backgroundColor: "white", padding: 40, alignItems: "center", width: "90%" }}>
                        <div className="icon">
                            <IconComponent size={60} color="orange" />
                        </div>
                        <div className="content">
                            <h4 style={{ color: "black" }}>{val.featureTitle}</h4>
                            {/* <p>{val.featureDescription}</p> */}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                {/*Feature section start*/}
                <div className={`feature-section section-space--inner--100 ${this.props.background}`}>
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="feature-item-wrapper">
                                <div className="row">
                                    {Datalist}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Feature section end*/}
            </div>
        );
    }
}

export default FeatureIcon;
