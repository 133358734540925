import React from 'react';

const MobileMenu = ({ isVisible, onClose }) => {
    return (
        <div>
            <div className={`offcanvas-mobile-menu ${isVisible ? 'active' : ''}`} id="mobile-menu-overlay">
                <a href="#/" className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={onClose}>
                    <i className="fi fi-br-cross" />
                </a>
                <div className="offcanvas-wrapper">
                    <div className="offcanvas-inner-content">
                        <div className="offcanvas-mobile-search-area">
                            <form action="#">
                                <input type="search" placeholder="Search ..." />
                                <button type="submit"><i className="fa fa-search" /></button>
                            </form>
                        </div>
                        <nav className="offcanvas-navigation">
                            <ul>
                                <li><a href={`/`}>HOME</a></li>
                                <li><a href={`/about-us`}>ABOUT</a></li>
                                <li><a href={`/services`}>SERVICES</a></li>
                                <li><a href={`/blog`}>BLOG</a></li>
                                <li><a href={`/contact-us`}>CONTACT</a></li>
                            </ul>
                        </nav>
                        <div className="offcanvas-widget-area">
                            <div className="off-canvas-contact-widget">
                                <div className="header-contact-info">
                                    <ul className="header-contact-info__list">
                                        <li><i className="ion-android-phone-portrait" /><a href="tel://12452456012">(01) 503-984-7493 </a></li>
                                        <li><i className="ion-android-mail" /><a href="mailto:info@yourdomain.com">info@kingpinllc.com</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="off-canvas-widget-social">
                                <a href="#/" title="Facebook"><i className="fa fa-facebook" /></a>
                                <a href="#/" title="Twitter"><i className="fa fa-twitter" /></a>
                                <a href="#/" title="LinkedIn"><i className="fa fa-linkedin" /></a>
                                <a href="#/" title="Youtube"><i className="fa fa-youtube-play" /></a>
                                <a href="#/" title="Vimeo"><i className="fa fa-vimeo-square" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
